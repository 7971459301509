import { FC, useState, ChangeEvent, useEffect, useRef } from "react"
import styled from "styled-components"
import { decode } from "shopify-gid"
import {
  useCheckout,
  useUI,
  useBodyScrollLock,
  useDictionary,
  useSite,
  useGeoIp,
  useCustomer,
  useTracking,
} from "@hooks/index"

import { TextBlock, CartUpsell, CartFooter } from "@components/common"
import { Sidebar, Button, LineItemThumbnail } from "@components/ui"
import ChevronThin from "@svg/chevron-thin.svg"
import EmptyBag from "@svg/empty-bag.svg"
import { useRouter } from "next/router"
import { Portal } from "@components/common"
import { currencyToFulfillmentId } from '@lib/config/shopify'
import * as api from '@lib/api'
import { getProductVariantsMetafields } from '@lib/shopify'
import { ProductVariant, Metafield, Node, OrderLineItem, OrderLineItemEdge } from "shopify-storefront-api-typings"

const BACKEND_API = 'https://backend.hautehijab.net/rest/api'

export default function Cart(): JSX.Element {
  const dictionary = useDictionary()
  const site = useSite()
  const { displayCart, closeCart, interactCart, addToBagClicked, cartInteracted} = useUI()
  useBodyScrollLock(displayCart)
  const {
    id,
    note,
    lineItems,
    middleLayer,
    lineItemsSubtotalPrice,
    addLineItem,
    updateLineItem,
    removeLineItem,
    webUrl,
    currencyCode,
    updateCheckoutAttributes,
    applyDiscountCode,
    removeLineItems
  } = useCheckout()

  const { customer, formatCurrencyPrice, getCurrencyPrice, currency: customerCurrency } = useCustomer()
  const { track, tikTokTrack } = useTracking()
  const [discountApplied, setDiscountApplied] = useState<boolean>(false);
  const router = useRouter();

  useEffect(() => {
    if(lineItems?.edges && !discountApplied && router.query.promo) {
      setDiscountApplied(true);
      applyDiscountCode(router.query.promo).then((errors: string[]) => {
        if(errors.length === 0) {
          track('Coupon Applied', {
            cart_id: id,
            coupon_name: router.query.promo,
          })
          return
        } 
        track('Coupon Denied', {
          cart_id: id,
          coupon: router.query.promo,
          reason: errors.join(',')
        })
      });
    }
  }, [lineItems])

  useEffect(() => {
    if (displayCart) {
      const products = lineItems?.edges.map(({ node }: { node: any }) => ({
        product_id: decode(node?.variant?.id).id,
        name: node?.title,
        sku: node?.variant?.sku,
        variant: node?.variant?.title,
        quantity: node?.quantity,
        image_url: node?.variant?.image?.transformedSrc,
        price: parseFloat(node?.variant?.priceV2?.amount),
        brand: "Haute Hijab",
      }))

      const contentsForTikTok = lineItems?.edges.map(({ node }: { node: any }) => ({
        content_id: node.variant?.product?.handle || null,
        content_type: 'product',
        content_name: node.title,
        quantity: node.quantity,
        price: node.variant?.presentmentPrices.edges.find((edge: {node: {price: {currencyCode: string}}}) => edge.node.price.currencyCode === customerCurrency)?.node.price.amount || null,
      }))

      track("Cart Viewed", { cart_id: decode(id).id, products })
      tikTokTrack("ViewContent", {
        content_category: "Cart Viewed",
        content_name: decode(id).id,
        contents: contentsForTikTok,
        value: subtotal,
        currency: customerCurrency
      })
    }
  }, [displayCart])
  
  // Auto close cart if add to bag clicked. Cancel this if cart is interacted with, or closed --> closeCart() will set addToBagClicked to false
  let closeCartTimer: any

  useEffect(() => {
    if(addToBagClicked && !cartInteracted) {
      closeCartTimer = setTimeout(() => {
        closeCart()
      }, 3000);
    }
    return () => clearTimeout(closeCartTimer);
  }, [cartInteracted, addToBagClicked]);

  const cartCount = lineItems?.edges
    ? lineItems.edges.reduce(
        (acc: number, val: { node: { quantity: number } }) =>
          acc + val.node.quantity,
        0
      )
    : 0

  const [addGiftWrapping, setAddGiftWrapping] = useState(false)
  const [giftMessage, setGiftMessage] = useState("")

  const giftWrap = lineItems?.edges?.filter(
    ({ node }: { node: any }) => node.variant.id === site.giftWrapping
  )[0]

  useEffect(() => {
    // Collapse gift wrapping text area on remove giftwrapping lineItem
    setAddGiftWrapping(!!giftWrap)
  }, [giftWrap])

  useEffect(() => {
    if (!note) return
    setGiftMessage(note)
  }, [note])

  const handleChecked = async (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target
    setAddGiftWrapping(checked)
  }

  useEffect(() => {
    const handleAddGiftWrapping = async () => {
      if (addGiftWrapping) {
        if (giftWrap) return
        await addLineItem({ quantity: 1, variantId: site.giftWrapping })

        track("Product Added", {
          cart_id: decode(id).id,
          product_id: decode(site?.giftWrapping).id,
          name: "Gift Wrapping",
        })
      } else {
        if (!giftWrap) return
        await removeLineItem(giftWrap.node)
        await updateCheckoutAttributes({ note: "" })
        setGiftMessage("")
        track("Product Removed", {
          cart_id: decode(id).id,
          product_id: decode(site?.giftWrapping).id,
          name: "Gift Wrapping",
        })
      }
    }
    handleAddGiftWrapping()
  }, [addGiftWrapping])

  const handleTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target

    setGiftMessage(value)
  }

  const handleRemoveLineItem = (lineItem: any) => {
    const isGiftWrap = site.giftWrapping === lineItem.variant.id
    if (isGiftWrap) {
      setAddGiftWrapping(false)
    }
    removeLineItem(lineItem)
  }

  const { country_code } = useGeoIp()

  const shippingThresholdCollection = site.shippingThresholdsCollection.items
  const shippingThreshold = shippingThresholdCollection.find(
    ({ countryCode}: { countryCode: string, currency: string }) => countryCode === country_code
  )

  const shippingThresholdText = shippingThreshold ? shippingThreshold.text : shippingThresholdCollection[0].text
  
  const subtotalForShipping = lineItemsSubtotalPrice?.amount
  const progress = subtotalForShipping / (shippingThreshold?.value / 100)
  const subtotal = lineItems?.edges?.reduce((collect: number, { node }: { node: any}) => {
    return collect + (node.quantity * getCurrencyPrice(node.variant))
  }, 0)

  const [showAlert, setShowAlert] = useState(false)

  const cartRef = useRef()

  const handleCheckout = () => {
    setShowAlert(false)
    const contentsForTikTok = lineItems?.edges.map(({ node }: { node: any }) => ({
      content_id: node.variant?.product?.handle || null,
      content_type: 'product',
      content_name: node.title,
      quantity: node.quantity,
      price: node.variant?.presentmentPrices.edges.find((edge: {node: {price: {currencyCode: string}}}) => edge.node.price.currencyCode === customerCurrency)?.node.price.amount || null,
    }))
    tikTokTrack("InitiateCheckout", {
      contents: contentsForTikTok,
      content_id: id,
      value: subtotal,
      currency: customerCurrency
    })
    if (customer) {
      api.getMultipassUrl(customer.email, webUrl).then(url => window.location.href = (url))
      return
    }
    window.location.href = (webUrl)
  }

  const validateCheckout = () => {
    const variantMap = lineItems?.edges.reduce((collect: { [key: string]: any}, edge: { node: OrderLineItem & { id: string } }) => {
      if (!edge.node.variant) return collect
      collect[edge.node.variant.id] = edge.node.id
      return collect
    }, {})

    const locationId = currencyToFulfillmentId(currencyCode)
    const ids = Object.keys(variantMap)

    getProductVariantsMetafields(ids)
    .then(async (variants: ProductVariant[]) => {
      const unavailableVariantIds: string[] = variants.filter((variant: ProductVariant) => {
        const inventory = variant.metafields.edges.map(({ node }: { node: Metafield }) => node).find((metafield: Metafield) => metafield.key === `location:${locationId}`)
        return inventory ? Number(inventory.value) <= 0 : false
      }).map((variant: ProductVariant) => variant.id)
      
      if (unavailableVariantIds.length === 0) {
        handleCheckout()
        return
      }
      const nodesToRemove = unavailableVariantIds.map((variantId: string) => lineItems.edges.find((lineItem: any) => lineItem.node.variant.id === variantId))
      await removeLineItems(nodesToRemove)
      setShowAlert(true)
    }).catch((err: Error) => {
      console.error(err)
      handleCheckout()
    })
  }

  return (
    <Sidebar fromRight isOpen={displayCart} handleClose={closeCart}>
      <StyledCart ref={cartRef} onClick={interactCart} onTouchStart={interactCart} onMouseEnter={interactCart}>
        <CartHeader>
          {`${dictionary.yourBag} (${cartCount})`}
          <CloseButton onClick={closeCart}>
            <CloseIcon />
          </CloseButton>
        </CartHeader>
        {shippingThreshold && shippingThreshold.currency === customerCurrency && (
          <ShippingProgress>
            <ProgressBarWrapper>
              <ProgressBar $progress={progress} />
            </ProgressBarWrapper>
            <ShippingText>
              {progress < 100 ? (
                <>
                  {`You're ${formatCurrencyPrice(shippingThreshold.value - subtotalForShipping)} from `}
                  <span>free shipping</span>
                </>
              ) : (
                <>
                  Your order qualifies for <span>free shipping</span>
                </>
              )}
            </ShippingText>
          </ShippingProgress>
        )}
        {lineItems?.edges?.length > 0 ? (
          <CartContent>
            <ScrollArea>
              <LineItemsContainer>
                <LineItems>
                  {
                    lineItems?.edges
                      .sort(function(a: any, b: any ) {
                        const aIndex = middleLayer.findIndex((variantId: any) => variantId === a.node.variant.id)
                        const bIndex = middleLayer.findIndex((variantId: any) => variantId === b.node.variant.id)
                        return aIndex - bIndex
                      })
                      .map(({ node }: { node: any }) => {
                        return (
                          <LineItemThumbnail
                            cart_id={decode(id).id}
                            key={node.variant.id}
                            lineItem={node}
                            removeLineItem={handleRemoveLineItem}
                            updateLineItem={updateLineItem}
                          />
                        )
                      })
                  }
                </LineItems>
                <Subtotal>
                  <span>{dictionary.subtotal}</span>
                  <span>
                    {formatCurrencyPrice(subtotal)}
                  </span>
                </Subtotal>
              </LineItemsContainer>
              {site?.upsellCartCollection?.items.length > 0 && (
                <CartUpsell
                  upsells={site.upsellCartCollection.items}
                  lineItems={lineItems?.edges}
                />
              )}
            </ScrollArea>
            <CartFooter
              onCheckout={validateCheckout}
              addGiftWrapping={addGiftWrapping}
              giftMessage={giftMessage}
              handleChecked={handleChecked}
              handleTextArea={handleTextArea}
            >
              <FooterTextWrapper>
                {shippingThreshold ? shippingThresholdText : <TextBlock textBlock={site.cartFooterTextFirstLine} />}
              </FooterTextWrapper>
              <FooterTextWrapper>
                <TextBlock textBlock={site.cartFooterTextSecondLine} />
              </FooterTextWrapper>
            </CartFooter>
          </CartContent>
        ) : (
          <PlaceholderContainer>
            <Placeholder>
              <EmptyBag />
              <p>{dictionary.yourBagIsEmpty}</p>
              <EmptyCartButton button={site.emptyCartButton} />
            </Placeholder>
          </PlaceholderContainer>
        )}
        <Portal open={displayCart && cartRef.current && showAlert} node={cartRef.current} onClose={() => void 0}>
          <AlertModal>
              <AlertTitle>Alert</AlertTitle>
              <AlertBody>
                We're sorry, some items are not available in your region and have been removed from your bag.
              </AlertBody>
              {lineItems?.edges?.length === 0 && (
                  <Button
                    boldTitle
                    title="Continue Shopping"
                    buttonTheme="ghostBlackReverse"
                    onClick={() => setShowAlert(false)}
                />)
              }
              {lineItems?.edges?.length > 0 && (
                <>
                  <Button
                    boldTitle
                    title="Checkout Now"
                    buttonTheme="ghostBlackReverse"
                    onClick={() => handleCheckout()}
                  />
                  <WrapLink>
                    <InlineLink onClick={() => setShowAlert(false)}>Continue shopping</InlineLink>
                  </WrapLink>
                </>
              )}
          </AlertModal>
        </Portal>
      </StyledCart>
    </Sidebar>
  )
}

const WrapLink = styled.div`
  margin-top: 1rem;
`

const AlertTitle = styled.h2`
  font-family: ${({ theme }) => theme.font.primary};
  font-size: 2rem;
  line-height: 2.4rem;
  text-align: center;
  letter-spacing: 4%;
  text-transform: uppercase;
`

const AlertBody = styled.h4`
  font-family: ${({ theme }) => theme.font.primary};
  font-size: 1.5rem;
  line-height: 2.4rem;
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`

const InlineLink = styled.span`
  font-size: 1.3rem;
  line-height: 3rem;
  text-decoration: underline;
  color: ${({ theme }) => theme.color.grey};
  cursor: pointer;
  padding-top: 0.5rem;
`

const AlertModal = styled.div`
  height: 29rem;
  background: white;
  z-index: 100000;
  width: 29rem;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledCart = styled.div<any>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
`

const CartContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
`

const ScrollArea = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
`

const CartHeader = styled.header`
  position: relative;
  padding: 1.3rem 2.1rem;
  text-align: center;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.8rem;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.white};
  background: ${({ theme }) => theme.color.greyBlack};

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    padding: 2.1rem 0;
  }
`

const CloseButton = styled.button`
  position: absolute;
  right: 2.1rem;
  width: 1.44rem;
`

const CloseIcon = styled(ChevronThin)`
  transform: rotate(-90deg);

  path {
    stroke: ${({ theme }) => theme.color.white};
    opacity: 1;
  }
`

const ShippingProgress = styled.section`
  background: ${({ theme }) => theme.color.beige};
  color: ${({ theme }) => theme.color.grey};
  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 1.8rem;
  text-align: center;

  span {
    color: ${({ theme }) => theme.color.black};
  }
`

const ShippingText = styled.p`
  margin: 0;
  padding: 0.6rem 2rem;
`

const ProgressBarWrapper = styled.div`
  background: ${({ theme }) => theme.color.greyBlack};
  border-bottom: 2px solid ${({ theme }) => theme.color.greyBlack};
`

const ProgressBar = styled.div<{ $progress: number }>`
  width: ${({ $progress }) => $progress}%;
  height: 0.5rem;
  background: ${({ theme }) => theme.color.white};
  border: none;
  border-radius: none;
  box-shadow: none;
`

const PlaceholderContainer = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;

  p {
    margin: 2rem 0;
    font-family: ${({ theme }) => theme.font.heading};
    text-transform: uppercase;
    font-size: 2rem;
    line-height: 2.4rem;
    letter-spacing: 0.03rem;
  }
`

const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 13.6rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    margin-top: 12.4rem;
  }
`

const EmptyCartButton = styled(Button)`
  padding: 1.3rem 5rem;
`

const LineItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding: 2.1rem 0 1.65rem 0;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    padding: 3.3rem 0 2rem 0;
  }
`

const LineItems = styled.ul``

const Subtotal = styled.div`
  display: flex;
  margin: auto 2rem 0;
  padding: 1.95rem 0 0;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.color.greyLight};
  font-family: ${({ theme }) => theme.font.heading};
  font-size: 2rem;
  line-height: 2.4rem;
  letter-spacing: 0.03rem;
  text-transform: uppercase;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    padding: 1.35rem 0 0;
  }
`

const FooterTextWrapper = styled.div`
  margin: 0 0 0.8rem;
  font-size: 1.1rem;
  text-align: center;

  &:last-child {
    margin: 0;
  }

  p {
    margin: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    font-size: 1.2rem;
  }
`
